import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import clsx from 'clsx';
import {Event} from '../../GAClickEvent';
import '../../../assets/css/header.css';
import useScrollHandler from './useScrollHandler';
import useMediaIsDesktop from './useMediaIsDesktop';
import useStaticQueryForLogo from './useStaticQueryForLogo';
import {NAVBAR_TRANSPARENT, NAVBAR_WHITE, NAVBAR_HOMEPAGE} from './constants';
import {useEffect} from 'react';
import tintashLogoBlue from '../../../assets/images/logo/tintash-logo-blue.svg';
import tintashLogoWhite from '../../../assets/images/logo/tintash-logo-white.svg';
import '../../../assets/css/navbar.css';
import {IoMdArrowDropdown} from 'react-icons/io';

const activeLinkStyle = {
  color: '#30aefc',
};

const Header = ({position, isHomepage}) => {
  const getHeaderClass = () => {
    if (isHomepage) {
      return NAVBAR_HOMEPAGE;
    } else if (position === 'fixed') {
      return NAVBAR_WHITE;
    } else if (position === 'sticky') {
      return NAVBAR_TRANSPARENT;
    }
  };
  const [showCollapsedMenu, setShowCollapsedMenu] = useState(false);
  const [showFraudBanner, setShowFraudBanner] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const studioRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = event => {
    if (dropdownOpen && !studioRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleScrollOrMouseMove = event => {
    if (dropdownOpen && !studioRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', handleScrollOrMouseMove);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleScrollOrMouseMove);
    };
  }, [dropdownOpen]);

  const headerRef = useRef();
  useScrollHandler(headerRef, position, showFraudBanner, isHomepage);
  useMediaIsDesktop({query: '(min-width: 992px)'}, headerRef);
  const data = useStaticQueryForLogo();

  // creating function to load ip address from the API
  const getData = async () => {
    fetch('/where-am-i')
      .then(response => response.json())
      .then(ipData => {
        const {country} = ipData;
        if (country === 'HK') {
          setShowFraudBanner(true);
          localStorage.setItem('b', true);
        }
      });
  };

  useEffect(() => {
    // passing getData method to the lifecycle method
    getData();
  }, []);

  const toggleMenu = () => {
    setShowCollapsedMenu(preVal => !preVal);
  };

  return (
    <>
      {showFraudBanner ? (
        <div className="contact-disclaimer">
          <div>
            Fraudulent Activity Disclaimer! tintash.com and tintash.no are our only websites.&nbsp;
            <a href="/fraud-disclaimer" rel="noopener noreferrer">
              Learn more
            </a>
            .
          </div>
          <div
            onClick={() => {
              setShowFraudBanner(false);
              localStorage.setItem('b', false);
            }}
          >
            <i className="fa fa-times" />
          </div>
        </div>
      ) : null}

      <header ref={headerRef} className={clsx('nav-position', getHeaderClass())}>
        <div className="container-fluid main-padding">
          <div className="">
            <div className="">
              <nav id="nav" className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/">
                  <div id="logo-sticky">
                    <img
                      style={{display: 'inherit'}}
                      width="140"
                      id="logo-sticky"
                      className="img-fluid navbar-logo"
                      alt="Tintash Logo"
                      src={tintashLogoBlue}
                    />
                  </div>
                  <div id="logo">
                    <img
                      style={{display: 'inherit'}}
                      width="140"
                      id="logo"
                      className="img-fluid navbar-logo"
                      alt="Tintash Logo"
                      src={tintashLogoWhite}
                    />
                  </div>
                </Link>

                <button
                  className="navbar-toggler"
                  onClick={toggleMenu}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarToggler"
                  aria-controls="navbarTogglerDemo03"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className={`collapse navbar-collapse ${showCollapsedMenu ? 'show' : ''}`}
                  id="navbarToggler"
                >
                  <ul className="navbar-nav ml-auto">
                    <li
                      className="nav-item dropdown ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0 latoBold"
                      // onMouseEnter={() => setDropdownOpen(true)}
                      // onMouseLeave={() => setDropdownOpen(false)}
                      onClick={toggleDropdown}
                    >
                      <div
                        role="button"
                        className="nav-link nav-link-text"
                        // onClick={toggleDropdown}
                      >
                        Studios
                        <IoMdArrowDropdown />
                      </div>
                      <ul ref={studioRef} className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                        <Link
                          className="dropdown-custom-item-link"
                          to="/zerotoone/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <li className="dropdown-custom-item">
                            <span>Zero-to-One</span>
                          </li>
                        </Link>

                        <Link
                          className="dropdown-custom-item-link"
                          to="/web3/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <li className="dropdown-custom-item">
                            <span>Web 3.0</span>
                          </li>
                        </Link>

                        <Link
                          className="dropdown-custom-item-link"
                          to="/games/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <li className="dropdown-custom-item">
                            <span>Games</span>
                          </li>
                        </Link>

                        <Link
                          className="dropdown-custom-item-link"
                          to="/cloud/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <li className="dropdown-custom-item">
                            <span>Cloud</span>
                          </li>
                        </Link>
                      </ul>
                    </li>

                    <li className="nav-item ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0">
                      <Link
                        activeStyle={activeLinkStyle}
                        onClick={() => Event('About Us', 'About Us button is clicked', 'Header')}
                        className="nav-link nav-link-text"
                        to="/about/"
                      >
                        About
                      </Link>
                    </li>

                    <li className="nav-item ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0">
                      <Link
                        activeStyle={activeLinkStyle}
                        onClick={() => Event('Portfolio', 'Portfolio button is clicked', 'Header')}
                        className="nav-link nav-link-text"
                        to="/portfolio/"
                      >
                        Portfolio
                      </Link>
                    </li>

                    <li className="nav-item ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0 latoBold">
                      <a
                        className="nav-link nav-link-text"
                        href="https://medium.com/tintash"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>Thinking</span>
                      </a>
                    </li>

                    <li className="nav-item ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0">
                      <Link
                        activeStyle={activeLinkStyle}
                        onClick={() => Event('Careers', 'Careers button is clicked', 'Header')}
                        className="nav-link nav-link-text"
                        to="https://tintash.zohorecruit.com/jobs/new-openings"
                      >
                        Careers
                      </Link>
                    </li>

                    <li className="nav-item ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0 latoBold">
                      <Link
                        onClick={() =>
                          Event('Contact Us', 'Contact Us button is clicked', 'Header')
                        }
                        className="nav-link nav-link-text"
                        to="/contact/"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  position: PropTypes.oneOf(['sticky', 'fixed']),
  isHomepage: PropTypes.bool,
};

Header.defaultProps = {
  position: 'sticky',
  isHomepage: false,
};

export default Header;
